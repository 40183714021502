import { useState } from "react";

const useImagePairs = () => {
  const [aImages, setAImages] = useState([]);
  const [bImages, setBImages] = useState([]);
  const [currentPair, setCurrentPair] = useState({ a: null, b: null });
  const [aScore, setAScore] = useState(0);
  const [bScore, setBScore] = useState(0);
  const [isComparing, setIsComparing] = useState(false);
  const [usedPairs, setUsedPairs] = useState(new Set());
  const [allComparisonsDone, setAllComparisonsDone] = useState(false);
  const [remainingPairs, setRemainingPairs] = useState(0);

  const handleAImageUpload = (side, files) => {
    setAImages(Array.from(files || []));
  };

  const handleBImageUpload = (side, files) => {
    setBImages(Array.from(files || []));
  };

  const getRandomPair = () => {
    const availablePairs = aImages
      .map((aImage) => {
        const bImage = bImages.find((bImage) => bImage.name === aImage.name);
        return bImage ? { a: aImage, b: bImage } : null;
      })
      .filter((pair) => pair !== null && !usedPairs.has(pair.a.name));

    setRemainingPairs(availablePairs.length);

    if (availablePairs.length === 0) {
      return null;
    }

    const randomIndex = Math.floor(Math.random() * availablePairs.length);
    const selectedPair = availablePairs[randomIndex];
    setUsedPairs(new Set(usedPairs).add(selectedPair.a.name));
    return selectedPair;
  };

  const startComparison = () => {
    setIsComparing(true);
    setAllComparisonsDone(false);
    const pair = getRandomPair();
    if (pair) {
      setCurrentPair(pair);
    }
  };

  const handleComparison = (result) => {
    if (result === "a") {
      setAScore(aScore + 1);
    } else if (result === "b") {
      setBScore(bScore + 1);
    } else if (result === "tie") {
      setAScore(aScore + 0.5);
      setBScore(bScore + 0.5);
    }
    const pair = getRandomPair();
    if (pair) {
      setCurrentPair(pair);
    } else {
      setIsComparing(false); // No more pairs to compare
      setAllComparisonsDone(true); // All comparisons are done
    }
  };

  return {
    aImages,
    bImages,
    handleAImageUpload,
    handleBImageUpload,
    startComparison,
    currentPair,
    handleComparison,
    aScore,
    bScore,
    isComparing,
    allComparisonsDone, // Expose the new state
    remainingPairs, // Expose the remaining pairs count
  };
};

export default useImagePairs;

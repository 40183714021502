import React from "react";
import { Box, Text, VStack, HStack } from "@chakra-ui/react";

const ScoreDisplay = ({ score }) => {
  return (
    <Box
      border="1px solid"
      borderColor="teal.500"
      borderRadius="md"
      p={4}
      boxShadow="md"
      bg="teal.50"
      _hover={{ boxShadow: "lg" }}
    >
      <VStack spacing={2}>
        <Text fontSize="2xl" fontWeight="bold" color="teal.700">
          Current Score
        </Text>
        <HStack spacing={4} alignItems="flex-start">
          <VStack>
            <Text fontSize="4xl" fontWeight="extrabold" color="teal.900">
              {score.a}
            </Text>
            <Text fontSize="sm" fontWeight="medium" color="teal.600">
              Side A
            </Text>
          </VStack>
          <Text
            fontSize="4xl"
            fontWeight="extrabold"
            color="teal.900"
            lineHeight="1.2"
          >
            -
          </Text>
          <VStack>
            <Text fontSize="4xl" fontWeight="extrabold" color="teal.900">
              {score.b}
            </Text>
            <Text fontSize="sm" fontWeight="medium" color="teal.600">
              Side B
            </Text>
          </VStack>
        </HStack>
      </VStack>
    </Box>
  );
};

export default ScoreDisplay;

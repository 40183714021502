import React, { useState, useCallback } from "react";
import {
  Input,
  VStack,
  Text,
  Box,
  IconButton,
  List,
  ListItem,
  Spinner,
} from "@chakra-ui/react";
import { FaUpload } from "react-icons/fa";
import JSZip from "jszip";

const ImageUpload = ({ side, onUpload }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = useCallback(
    async (event) => {
      setIsLoading(true);
      const files = Array.from(event.target.files);
      const allFiles = await processFiles(files);
      setUploadedFiles((prevFiles) => [...prevFiles, ...allFiles]);
      onUpload(side, [...uploadedFiles, ...allFiles]);
      setIsLoading(false);
    },
    [onUpload, side, uploadedFiles]
  );

  const handleDrop = useCallback(
    async (event) => {
      event.preventDefault();
      setIsDragOver(false);
      setIsLoading(true);
      const files = Array.from(event.dataTransfer.files);
      const allFiles = await processFiles(files);
      setUploadedFiles((prevFiles) => [...prevFiles, ...allFiles]);
      onUpload(side, [...uploadedFiles, ...allFiles]);
      setIsLoading(false);
    },
    [onUpload, side, uploadedFiles]
  );

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setIsDragOver(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragOver(false);
  }, []);

  const processFiles = async (files) => {
    let allFiles = [];
    for (const file of files) {
      if (file.type === "application/zip") {
        const zip = new JSZip();
        const contents = await zip.loadAsync(file);
        for (const filename in contents.files) {
          const fileData = await contents.files[filename].async("blob");
          const newFile = new File([fileData], filename, {
            type: fileData.type || "image/png", // Ensure the type is set correctly
          });
          if (fileData.size > 0) {
            allFiles.push(newFile);
          }
        }
      } else {
        allFiles.push(file);
      }
    }
    return allFiles;
  };

  return (
    <VStack
      border="1px solid"
      borderColor={isDragOver ? "teal.500" : "gray.200"}
      borderRadius="md"
      p={4}
      spacing={3}
      boxShadow="md"
      _hover={{ boxShadow: "lg" }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <Text fontSize="lg" fontWeight="bold" color="teal.500">
        Upload images for {side.toUpperCase()} side
      </Text>
      <Box position="relative">
        <Input
          type="file"
          multiple
          onChange={handleFileChange}
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          opacity={0}
          cursor="pointer"
          zIndex={1}
        />
        <IconButton
          icon={<FaUpload />}
          aria-label="Upload"
          size="lg"
          colorScheme="teal"
          variant="outline"
          zIndex={0}
        />
      </Box>
      {isLoading && <Spinner size="lg" color="teal.500" />}
      {uploadedFiles.length > 0 && (
        <List spacing={2} mt={3}>
          {uploadedFiles.slice(0, 5).map((file, index) => (
            <ListItem key={index} fontSize="sm" color="teal.700">
              {file.name}
            </ListItem>
          ))}
          {uploadedFiles.length > 5 && (
            <ListItem fontSize="sm" color="teal.700">
              +{uploadedFiles.length - 5} more
            </ListItem>
          )}
        </List>
      )}
    </VStack>
  );
};

export default ImageUpload;

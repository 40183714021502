import React from "react";
import {
  ChakraProvider,
  Box,
  VStack,
  Heading,
  HStack,
  Button,
  Divider,
  useColorMode,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { FaSun, FaMoon } from "react-icons/fa";
import ImageUpload from "./components/ImageUpload";
import ImagePairViewer from "./components/ImagePairViewer";
import ScoreDisplay from "./components/ScoreDisplay";
import useImagePairs from "./hooks/useImagePairs";

function App() {
  const {
    handleAImageUpload,
    handleBImageUpload,
    startComparison,
    currentPair,
    handleComparison,
    aScore,
    bScore,
    isComparing,
    allComparisonsDone,
    remainingPairs, // Destructure remainingPairs from useImagePairs
  } = useImagePairs();

  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <ChakraProvider>
      <Box
        p={5}
        bg={colorMode === "light" ? "gray.100" : "gray.900"}
        minH="100vh"
      >
        <VStack spacing={4} align="stretch">
          <HStack justifyContent="space-between" w="100%">
            <Heading
              color={colorMode === "light" ? "teal.600" : "teal.300"}
              size="2xl"
              textAlign="center"
            >
              Compare
            </Heading>
            <IconButton
              icon={colorMode === "light" ? <FaMoon /> : <FaSun />}
              isRound
              size="lg"
              onClick={toggleColorMode}
              aria-label="Toggle color mode"
            />
          </HStack>
          <Divider
            borderColor={colorMode === "light" ? "gray.300" : "gray.700"}
          />
          {!isComparing && !allComparisonsDone && (
            <>
              <HStack spacing={4} justify="center">
                <ImageUpload side="a" onUpload={handleAImageUpload} />
                <ImageUpload side="b" onUpload={handleBImageUpload} />
              </HStack>
              <Button
                colorScheme="teal"
                size="lg"
                onClick={startComparison}
                mt={4}
              >
                Start
              </Button>
            </>
          )}
          {isComparing && (
            <ImagePairViewer
              aImage={currentPair.a}
              bImage={currentPair.b}
              onCompare={handleComparison}
            />
          )}
          {allComparisonsDone && (
            <Text fontSize="xl" color="teal.500" textAlign="center" mt={4}>
              All comparisons are done!
            </Text>
          )}
          <ScoreDisplay score={{ a: aScore, b: bScore }} />
          {isComparing && (
            <Text fontSize="md" color="teal.500" textAlign="center" mt={2}>
              Pairs remaining: {remainingPairs}
            </Text>
          )}
        </VStack>
      </Box>
    </ChakraProvider>
  );
}

export default App;

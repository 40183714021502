import React, { useState, useRef, useEffect, useCallback } from "react";
import { VStack, Image, Button, HStack, Text } from "@chakra-ui/react";

const ImagePairViewer = ({ aImage, bImage, onCompare }) => {
  const [zoom, setZoom] = useState(0.5);
  const [aImageSize, setAImageSize] = useState({ width: 0, height: 0 });
  const [bImageSize, setBImageSize] = useState({ width: 0, height: 0 });
  const [isFlipped, setIsFlipped] = useState(Math.random() < 0.5);

  const aImageRef = useRef(null);
  const bImageRef = useRef(null);

  useEffect(() => {
    if (
      aImageRef.current &&
      aImageSize.width === 0 &&
      aImageSize.height === 0
    ) {
      setAImageSize({
        width: aImageRef.current.naturalWidth,
        height: aImageRef.current.naturalHeight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aImageSize]);

  useEffect(() => {
    if (
      bImageRef.current &&
      bImageSize.width === 0 &&
      bImageSize.height === 0
    ) {
      setBImageSize({
        width: bImageRef.current.naturalWidth,
        height: bImageRef.current.naturalHeight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bImageSize]);

  const handleCompare = useCallback(
    (choice) => {
      setIsFlipped(Math.random() < 0.5); // Flip the images randomly each time a button is clicked
      if (isFlipped) {
        if (choice === "left") {
          onCompare("b");
        } else if (choice === "right") {
          onCompare("a");
        } else {
          onCompare(choice);
        }
      } else {
        if (choice === "left") {
          onCompare("a");
        } else if (choice === "right") {
          onCompare("b");
        } else {
          onCompare(choice);
        }
      }
    },
    [isFlipped, onCompare]
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "q":
          handleCompare("left");
          break;
        case "e":
          handleCompare("right");
          break;
        case "w":
          handleCompare("tie");
          break;
        case "s":
          handleCompare("skip");
          break;
        case "+":
          handleZoomIn();
          break;
        case "-":
          handleZoomOut();
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleCompare]);

  const aImageUrl =
    typeof aImage === "object" ? URL.createObjectURL(aImage) : aImage;
  const bImageUrl =
    typeof bImage === "object" ? URL.createObjectURL(bImage) : bImage;

  const handleZoomIn = () => {
    setZoom((prevZoom) => {
      const newZoom = prevZoom * 2;
      return newZoom <= 5 ? newZoom : prevZoom;
    });
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => {
      const newZoom = prevZoom / 2;
      return newZoom >= 0.25 ? newZoom : prevZoom;
    });
  };

  return (
    <VStack>
      <HStack>
        {isFlipped ? (
          <>
            <Image
              src={bImageUrl}
              ref={bImageRef}
              width={`${bImageSize.width * zoom}px`}
              height={`${bImageSize.height * zoom}px`}
              style={{ imageRendering: "pixelated" }}
            />
            <Image
              src={aImageUrl}
              ref={aImageRef}
              width={`${aImageSize.width * zoom}px`}
              height={`${aImageSize.height * zoom}px`}
              style={{ imageRendering: "pixelated" }}
            />
          </>
        ) : (
          <>
            <Image
              src={aImageUrl}
              ref={aImageRef}
              width={`${aImageSize.width * zoom}px`}
              height={`${aImageSize.height * zoom}px`}
              style={{ imageRendering: "pixelated" }}
            />
            <Image
              src={bImageUrl}
              ref={bImageRef}
              width={`${bImageSize.width * zoom}px`}
              height={`${bImageSize.height * zoom}px`}
              style={{ imageRendering: "pixelated" }}
            />
          </>
        )}
      </HStack>
      <HStack>
        <Button
          colorScheme="teal"
          variant="solid"
          onClick={() => handleCompare("left")}
        >
          Left (Q)
        </Button>
        <Button
          colorScheme="teal"
          variant="solid"
          onClick={() => handleCompare("tie")}
        >
          Tie (W)
        </Button>
        <Button
          colorScheme="teal"
          variant="solid"
          onClick={() => handleCompare("right")}
        >
          Right (E)
        </Button>
        <Button
          colorScheme="blue"
          variant="solid"
          onClick={() => handleCompare("skip")}
        >
          Skip (S)
        </Button>
      </HStack>
      <HStack>
        <Button colorScheme="teal" variant="outline" onClick={handleZoomOut}>
          -
        </Button>
        <Text
          fontSize="lg"
          fontWeight="bold"
          color="teal.500"
        >{`Zoom: ${zoom}x`}</Text>
        <Button colorScheme="teal" variant="outline" onClick={handleZoomIn}>
          +
        </Button>
      </HStack>
    </VStack>
  );
};

export default ImagePairViewer;
